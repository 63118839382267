@tailwind base;
@tailwind components;
@tailwind utilities;
@layer components {
    .min-w-12 {
        min-width: 12px;
    }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

html {
    overflow: scroll !important;
    padding-right: 0px !important;
    overflow-x: hidden !important;
  }

.swiper-button-next {
    color: black !important;
    width: 3rem !important;
    height: 3rem !important;
    border-radius: 50% !important;
    background-color: white;
    box-shadow: 0 0 7px rgba(0, 0, 0, 0.2);
}

.swiper-button-next::after {
    font-size: 1rem !important;
    font-weight: bold;
}

.swiper-button-prev {
    color: black !important;
    width: 3rem !important;
    height: 3rem !important;
    border-radius: 50% !important;
    background-color: white;
    box-shadow: 0 0 7px rgba(0, 0, 0, 0.2);
}

.swiper-button-prev::after {
    font-size: 1rem !important;
    font-weight: bold;
}

.py-6.sm\:py-8.lg\:py-12.mt-10 {
    width: 32rem;
}

.w-lg {
width: 32rem;}



.swiper {
    padding-bottom: 16px !important;
    overflow: visible !important;
}

#image-uploader img {
    height: 400px !important;
}

